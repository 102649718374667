<template>
  <div class="rich-text-editor">
    <span class="rich-text-editor__label">
      {{ label }}
    </span>
    <VueEditor
      ref="editor"
      v-model="innerValue"
      :placeholder="placeholder"
      :editor-toolbar="toolbar"
      :class="{
        error: isError,
        'single-line': isSingleLine,
        'm-disabled': isDisabled,
      }"
      :disabled="isDisabled"
      @selection-change="handleSelectionChange"
    />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Vue from "vue";
import RichTextEditorVariables from "@/components/common/EditorVariables.vue";

export default {
  name: "RichTextEditor",
  components: { VueEditor },
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    hasSize: {
      type: Boolean,
      default: true,
    },
    hasDecoration: {
      type: Boolean,
      default: true,
    },
    hasAlignment: {
      type: Boolean,
      default: true,
    },
    hasList: {
      type: Boolean,
      default: true,
    },
    hasColor: {
      type: Boolean,
      default: true,
    },
    hasLink: {
      type: Boolean,
      default: true,
    },
    hasImage: {
      type: Boolean,
      default: false,
    },
    isSingleLine: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isVariablesOpened: false,
      variablesButton: null,
      prevSelection: null,
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    toolbar() {
      return [
        this.hasSize && [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        this.hasDecoration && ["bold", "italic", "underline"],
        this.hasAlignment && [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        this.hasList && [{ list: "ordered" }, { list: "bullet" }],
        this.hasColor && [{ color: [] }, { background: [] }],
        this.hasLink && ["link"],
        this.hasImage && ["image"],
        ["clean"],
      ].filter(Boolean);
    },
  },
  watch: {
    isVariablesOpened(isOpened) {
      if (isOpened) {
        const editorElement = this.$refs.editor.$el;
        const container = this.$refs.editor.quill.container;
        const mountNode = document.createElement("div");
        editorElement.insertBefore(mountNode, container);
        const variablesConstructor = Vue.extend(RichTextEditorVariables);
        const variablesComponent = new variablesConstructor({
          propsData: {
            options: this.variables,
          },
        });
        variablesComponent.$mount(mountNode);
        variablesComponent.$on("select", this.insertVariable);
        this.variablesButton.classList.add("m-active");
      } else {
        const variables = this.$refs.editor.$el.querySelector(
          ".rich-text-editor-variables"
        );
        variables.remove();
        this.variablesButton.classList.remove("m-active");
      }
    },
  },
  mounted() {
    if (this.variables.length) {
      const toolbarElement = this.$refs.editor.$el.querySelector(".ql-toolbar");
      const variablesWrapper = document.createElement("span");
      variablesWrapper.className = "ql-formats";
      this.variablesButton = document.createElement("button");
      this.variablesButton.type = "button";
      this.variablesButton.className = "ql-clean ql-variables-button";
      this.variablesButton.innerHTML =
        '<svg width="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M4.56 15.36H3.248C2.544 15.36 1.99467 15.1573 1.6 14.752C1.20533 14.3467 1.008 13.792 1.008 13.088V9.424C1.008 8.79467 0.72 8.48 0.144 8.48H0V6.88H0.144C0.72 6.88 1.008 6.576 1.008 5.968V2.272C1.008 1.568 1.20533 1.01333 1.6 0.608C1.99467 0.202667 2.544 0 3.248 0H4.56V1.6H3.792C3.45067 1.6 3.21067 1.67467 3.072 1.824C2.944 1.97333 2.88 2.224 2.88 2.576V6.064C2.88 6.94933 2.55467 7.488 1.904 7.68C2.55467 7.872 2.88 8.42133 2.88 9.328V12.784C2.88 13.136 2.944 13.3867 3.072 13.536C3.21067 13.6853 3.45067 13.76 3.792 13.76H4.56V15.36Z" />\n' +
        '<path d="M10.3741 15.36H9.06213V13.76H9.83012C10.1715 13.76 10.4061 13.6853 10.5341 13.536C10.6728 13.3867 10.7421 13.136 10.7421 12.784V9.328C10.7421 8.42133 11.0675 7.872 11.7181 7.68C11.0675 7.488 10.7421 6.94933 10.7421 6.064V2.576C10.7421 2.224 10.6728 1.97333 10.5341 1.824C10.4061 1.67467 10.1715 1.6 9.83012 1.6H9.06213V0H10.3741C11.0781 0 11.6275 0.202667 12.0221 0.608C12.4168 1.01333 12.6141 1.568 12.6141 2.272V5.968C12.6141 6.576 12.9021 6.88 13.4781 6.88H13.6221V8.48H13.4781C12.9021 8.48 12.6141 8.79467 12.6141 9.424V13.088C12.6141 13.792 12.4168 14.3467 12.0221 14.752C11.6275 15.1573 11.0781 15.36 10.3741 15.36Z" />\n' +
        "</svg>\n";
      this.variablesButton.onclick = () =>
        (this.isVariablesOpened = !this.isVariablesOpened);
      variablesWrapper.appendChild(this.variablesButton);
      toolbarElement.appendChild(variablesWrapper);
    }
  },
  methods: {
    insertVariable(variable) {
      const quill = this.$refs.editor.quill;
      const index =
        this.prevSelection
          ? this.prevSelection.index
          : quill.getLength() - 1;
      quill.deleteText(index, this.prevSelection?.length || 0);
      const text = `{{${variable.value}}}`;
      quill.insertText(index, text, "user");
      quill.focus();
      quill.setSelection(index, text.length);
      this.isBlured = false;
    },
    handleSelectionChange(_, prevSelection) {
      this.prevSelection = prevSelection;
    },
  },
};
</script>

<style lang="scss">
.quillWrapper {
  background: $white;

  .ql-toolbar {
    border-radius: 8px 8px 0 0;
    border-color: $secondary-400 !important;
    border-width: 2px !important;
    padding: 20px 20px 12px !important;

    .ql-variables-button {
      svg {
        fill: $secondary-500;
      }

      &:hover {
        svg {
          fill: $primary-hover;
        }
      }

      &.m-active {
        svg {
          fill: $primary;
        }
      }
    }
  }

  .ql-container {
    border-radius: 0 0 8px 8px;
    border-color: $secondary-400 !important;
    border-width: 2px !important;

    .ql-editor {
      padding: 20px !important;
      font-family: $font-family;

      &.ql-blank:before {
        color: $secondary-400;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        left: 20px;
      }
    }

    .ql-tooltip {
      z-index: 10;
    }

    &.ql-disabled {
      background-color: $secondary-100;
      color: $secondary-400;
      cursor: not-allowed;
    }
  }

  &.error {
    .ql-toolbar {
      border-color: $danger !important;
    }

    .ql-container {
      border-color: $danger !important;
    }
  }

  &.single-line {
    .ql-container {
      max-height: 64px;

      .ql-editor {
        overflow-y: hidden;
        min-height: unset;
        white-space: nowrap;
      }
    }
  }

  &.m-disabled {
    .ql-toolbar {
      background-color: $secondary-100;
      color: $secondary-400;
      cursor: not-allowed;

      > * {
        pointer-events: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.rich-text-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $secondary-500;
  }
}
</style>
